import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ChangeLanguage } from './ChangeLanguage'
import CardNumberForm from './CardNumberForm'
import { useHistory } from 'react-router-dom'
import { seleckCheckcodeStatus } from '../../store/codeCheck/'
import { CheckCodeRequest } from '../../models/services'
import Header from '../UiKit/Header'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { SubmitHandler } from 'react-hook-form'
import { setGiftCode } from '../../store/codeCheck/'

export const EnterCustomerNumber: React.FunctionComponent = () => {
  const [t, {language}] = useTranslation<string>()
  const dispatch = useAppDispatch()
  const status = useAppSelector(seleckCheckcodeStatus)
  const history = useHistory()

  const onSubmit: SubmitHandler<CheckCodeRequest> = async values => {
    dispatch(setGiftCode(values))
    history.push('/register')
  }

  return (
    <Container>
      <div className="row">
        <div className="area">
          <ChangeLanguage />
          <br />
          <div className="margin-1" id="contentBox">
            <div id="codeValidationForm" className="ui transition visible first page">
              <div className="ui transition hidden icon error message" id="errorMessageContainer">
                <i className="warning icon"></i>
                <div className="content">
                  <p className="message"></p>
                </div>
              </div>
              <div className="ui transition hidden icon success message">
                <i className="checkmark icon"></i>
                <div className="content">
                  <p className="message"></p>
                </div>
              </div>
              <Header title={t('registerAsAMuseumCard')} showIcon={false} />
              <p className="text-line-height">&nbsp;</p>
              <div className="ui center aligned segment central" id="summaryText">
                <p className="alignText">{t('enterYourCustomerNumber1')} </p>
                <p className="alignText">{t('enterYourCustomerNumber2')}</p>
                <p className="alignText">{t('enterYourCustomerNumber3')}</p>
                <a
                  className="alignText"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://rekisteroidy.museokortti.fi/${language}`}
                >
                  <span> {t('IfYouReceivedaMuseumCard')}</span>
                </a>
              </div>
              <div className="sixteen wide computer twelve wide mobile centered field">
                <CardNumberForm onSubmit={onSubmit} status={status} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

const Container = styled.div`
  justify-content: center;
  display: flex;
  .area {
    width: 536px;
    background: white;
  }
  .row {
    background-color: #f7f7f7;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: inherit;
    align-items: stretch;
    width: 100% !important;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .ui.selection.dropdown.locale-select {
    min-width: 35px;
    min-height: auto;
    padding: 5px 5px;
    border-radius: 0;
    z-index: 10;
    border: none;
    box-shadow: none;
    float: right;
  }
  .ui.selection.active.dropdown.locale-select:hover {
    box-shadow: none;
  }
  .ui.selection.active.dropdown.locale-select:hover .menu {
    box-shadow: none;
  }
  .ui.selection.dropdown.locale-select .menu > .item:hover {
    background: initial;
  }
  .ui.selection.dropdown.locale-select.left-aligned {
    position: absolute;
    left: 0.75em;
    top: 0.75em;
  }
  .ui.selection.dropdown.locale-select.right-aligned {
    position: absolute;
    right: 0.25em;
    top: 2em;
  }
  .ui.selection.dropdown.locale-select.right-aligned-osta-main {
    position: absolute;
    right: 1em;
    top: 12em;
  }
  .ui.selection.dropdown.locale-select .dropdown.icon {
    position: static;
    padding-left: 0.8em;
  }
  .ui.selection.dropdown.locale-select .menu {
    border-radius: 0;
    border: none;
    box-shadow: none;
  }
  .ui.selection.dropdown.locale-select .menu .item {
    padding: 5px 5px !important;
  }
  .ui.selection.dropdown.locale-select span {
    color: #000;
    margin: 0 0 0 0;
    word-spacing: 4px;
    font-size: 13px;
    font-weight: bold;
  }
  .margin-1 {
    margin: 1rem;
  }
  .image {
    width: 32px;
    padding-top: 1rem;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }
  .padding-5 {
    padding: 0 0.5rem;
  }
  .text-line-height {
    line-height: 0.5rem;
  }
  .alignText {
    text-align: center;
  }
  .full-div {
    width: 100%;
  }
  .margin-auto {
    margin: auto;
  }
  .padding-1 {
    vertical-align: top;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
`
