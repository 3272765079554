// eslint-disable-line @typescript-eslint/no-unused-vars
import { VoidFunctionComponent } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { CheckBoxRequest } from '../../models/services'
import styled from 'styled-components'
import { Form } from 'antd'
import { useTranslation } from 'react-i18next'
import CheckBox from '../UiKit/CheckBox'
import { colors } from '../../constants/colors'
import Text from '../UiKit/Text'
import Input from '../UiKit/Input'
import { CampaignCodeRequest } from '../../models/services/CampaignCodeRequest'
import { yupResolver } from '@hookform/resolvers/yup'
import campaignCodeSchema from '../../models/schemas/campaignCodeSchema'
import { CompaignCodeField } from './CompaignCodeField'
import { generateFieldUuid } from 'utils'
interface Props {
  onSubmit: SubmitHandler<CampaignCodeRequest>
  isSubmitting?: boolean
  handleChange: (e: any, value: boolean) => void
  checkboxes: CheckBoxRequest
}

const CheckBoxForm: VoidFunctionComponent<Props> = ({ onSubmit, isSubmitting, handleChange, checkboxes }) => {
  const { t } = useTranslation<string>()

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<any>({ resolver: yupResolver(campaignCodeSchema) })

  const compaignCode = Object.entries(CompaignCodeField)

  return (
    <Form onBlur={handleSubmit(onSubmit)}>
      <Container id="checkBox">
        <CheckBox
          id={generateFieldUuid('terms')}
          className="checkbox-distance"
          isChecked={!!checkboxes.terms}
          onToggle={() => handleChange('terms', !checkboxes.terms)}
        >
          <Text className={!checkboxes.terms ? 'text-decoration-error' : 'text-decoration'}>
            {t('IHaveReadAndAccept')}

            <a
              target="_blank"
              rel="noopener noreferrer"
              className="text-decoration"
              href={'http://www.museot.fi/kayttoehdot'}
            >
              <Text className="hyperlink-text">{t('termsAndConditions')}</Text>
            </a>
            {t('termsAndConditions2')}
          </Text>
        </CheckBox>
        <CheckBox
          id={generateFieldUuid('newsletter')}
          className="checkbox-distance"
          isChecked={!!checkboxes.newsletter}
          onToggle={() => handleChange('newsletter', !checkboxes.newsletter)}
        >
          <Text>{t('receiveTheNewsletter')}</Text>
        </CheckBox>
        <CheckBox
          id={generateFieldUuid('campaign')}
          className="checkbox-distance"
          isChecked={!!checkboxes.campaign}
          onToggle={() => handleChange('campaign', !checkboxes.campaign)}
        >
          <Text>{t('iHaveCampaignCode')}</Text>
        </CheckBox>

        {!!checkboxes.campaign && (
          <Form.Item name="coupon_code">
            <Input
              containerClassName="input-code"
              errorClassName="ant-form-item-has-error"
              control={control}
              name={'coupon_code'}
              placeholder={'Type your campaign voucher code here'}
              error={errors[compaignCode[0][0]]?.message}
              id={generateFieldUuid('coupon_code')}
            />
          </Form.Item>
        )}
      </Container>
    </Form>
  )
}

export default CheckBoxForm
const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 5px 0;
  .checkbox-distance {
    margin: 5px;
  }
  .text-decoration {
    text-decoration: none;
  }
  .text-decoration-error {
    color: ${colors.red};
    text-decoration: none;
  }
  .checkbox-distance {
    margin: 5px;
  }
`
