/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CheckCodeRequest } from './CheckCodeRequest'
import { request as __request } from '../core/request'
import type { RegisterRequest } from './RegisterRequest'

export class AppService {
  /**
   * Verify update email
   * By calling this end point the currently authenticated user might confirm update of the email.
   * @param requestBody App verify change user email request body
   * @returns void
   * @throws ApiError
   */
  public static async checkCode(requestBody: CheckCodeRequest): Promise<void> {
    const result = await __request({
      method: 'POST',
      path: `/web/user/gift4you/check`,
      body: requestBody,
      errors: {
        422: `Unprocessable Entity.`,
      },
    })
    return result.body
  }
  public static async register(requestBody: RegisterRequest, lang: string): Promise<void> {
    const result = await __request({
      method: 'POST',
      path: `​/web/user/gift4you/register`,
      body: requestBody,
      query: {
        'lang': lang,
      },
      errors: {
        500: `internal error`,
      },
    })
    return result.body
  }
}
