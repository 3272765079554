import React from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ClipLoader from 'react-spinners/ClipLoader'
import { colors } from '../../constants/colors'
import { HeaderFooterSRC } from '../../constants/index'

export default function Header() {
  const [header, setHeader] = useState('')
  const language = useTranslation().i18n.language

  useEffect(() => {
    const lang = language || localStorage.getItem('i18nextLng') || 'fi'
    fetch(`${HeaderFooterSRC.header}?lang=${lang}`)
      .then(function (response) {
        return response.text()
      })
      .then(function (data) {
        const head = document.head

        const headerStyleJs = document.createElement('script')
        headerStyleJs.type = 'text/javascript'
        headerStyleJs.src = HeaderFooterSRC.defer
        headerStyleJs.async = true
        document.body.appendChild(headerStyleJs)

        const headerStylemobi = document.createElement('script')
        headerStylemobi.type = 'text/javascript'
        headerStylemobi.src = HeaderFooterSRC.mobiilinavi
        headerStyleJs.async = true
        document.body.appendChild(headerStylemobi)

        const pageStyleLink = document.createElement('link')
        pageStyleLink.type = 'text/css'
        pageStyleLink.rel = 'stylesheet'
        pageStyleLink.href = HeaderFooterSRC.page_style
        head.appendChild(pageStyleLink)

        const headerStyleLink = document.createElement('link')
        headerStyleLink.type = 'text/css'
        headerStyleLink.rel = 'stylesheet'
        headerStyleLink.href = HeaderFooterSRC.header_style
        head.appendChild(headerStyleLink)

        const mobiilinaviCss = document.createElement('link')
        mobiilinaviCss.type = 'text/css'
        mobiilinaviCss.rel = 'stylesheet'
        mobiilinaviCss.href = HeaderFooterSRC.mobiilinavi_style
        head.appendChild(mobiilinaviCss)

        setHeader(data)
      })
  }, [language])

  return header ? <div dangerouslySetInnerHTML={{ __html: header }} /> : <ClipLoader size={5} color={colors.primary} />
}
