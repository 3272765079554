import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import RegisterForm from './RegisterForm'
import { RegisterFormRequest, RegisterRequest } from 'models/services'
import { selectData } from '../../store/codeCheck/'
import CheckBoxForm from './CheckBoxForm'
import { registerRequestAsync } from '../../store/register/asyncActions'
import { resetError, seleckRegisterStatus, selectErrorData } from '../../store/register'
import { useAppSelector, useAppDispatch } from '../../store/hooks'
import { CheckBoxRequest } from '../../models/services'
import Return from './Return'
import Header from '../UiKit/Header'
import { Status } from 'models'
import { useHistory } from 'react-router-dom'
import { CampaignCodeRequest } from '../../models/services/CampaignCodeRequest'
import { SubmitHandler } from 'react-hook-form'
import ErrorMessageForm from './ErrorMessage'

export const Register: React.FunctionComponent = () => {
  const { t, i18n } = useTranslation<string>()

  const data = useAppSelector(selectData)
  const status = useAppSelector(seleckRegisterStatus)
  const history = useHistory()
  const errorData = useAppSelector(selectErrorData)
  const dispatch = useAppDispatch()
  const [checkboxes, setCheckBoxes] = useState<CheckBoxRequest>({
    terms: false,
    newsletter: false,
    campaign: false,
  })
  const [couponCode, setCouponCode] = useState<CampaignCodeRequest>(null)

  useEffect(() => {
    if (status === Status.SUCCEEDED) {
      history.push('/thank-you')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, history])

  useEffect(() => {
    return () => {
      dispatch(resetError())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = (values: RegisterFormRequest) => {
    const newObj: RegisterRequest = {
      ...values,
      newsletter: checkboxes.newsletter,
      contact_language: i18n.language,
      plasticCard: true,
      gift4you_code: data.gift4you_code,
      coupon_code: couponCode?.coupon_code,
    }
    dispatch(registerRequestAsync(newObj))
  }

  const onSubmitCompagin: SubmitHandler<CampaignCodeRequest> = value => {
    setCouponCode(value)
  }
  const handleChange = (e: any, value: boolean) => {
    setCheckBoxes(prevState => ({
      ...prevState,
      [e]: value,
    }))
  }
  return (
    <Container>
      <div className="row">
        <div className="main-cointainer area">
          <div className="return-button">
            <Return />
          </div>
          {status === Status.FAILED && <ErrorMessageForm errorMessage={errorData} />}
          <div className="margin-1 aligned-container" id="contentBox">
            <div id="codeValidationForm" className="ui transition visible first page">
              <Header title={t('registerAsAMuseumCard')} />
              <p className="text-line-height">&nbsp;</p>
              <div className="ui center aligned segment central" id="summaryText">
                <p className="alignText">{t('fillInTheInformationForTheDelivery')} </p>
                <RegisterForm onSubmit={onSubmit} disabled={checkboxes.terms} />
                <CheckBoxForm handleChange={handleChange} checkboxes={checkboxes} onSubmit={onSubmitCompagin} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media only screen and (max-width: 768px) {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  @media (min-width: 1200px) {
    padding-left: 0;
    padding-right: 0;
  }
  .aligned-container {
    margin: 1rem;
    padding: 0 3rem 2rem 3rem;
    @media only screen and (max-width: 768px) {
      padding: 0;
    }
  }
  .row {
    background-color: #f7f7f7;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: inherit;
    align-items: stretch;
    width: 100% !important;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .return-button {
    text-align: left;
    margin: 20px 0;
  }
`
