import { VoidFunctionComponent } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Form, Spin } from 'antd'
import Input from '../UiKit/Input'
import { RegisterFormFields } from './RegisterFormFields'
import { RegisterFormRequest } from '../../models/services'
import { yupResolver } from '@hookform/resolvers/yup'
import registerSchema from '../../models/schemas/registerSchema'
import { useTranslation } from 'react-i18next'
import Button from '../UiKit/Button'
import DoubleInputContainer from '../UiKit/Input/DoubleInputContiner'
import { generateFieldUuid } from 'utils'
import styled from 'styled-components'
import { colors } from 'constants/colors'
import { useAppSelector } from 'store/hooks'
import { seleckRegisterStatus } from 'store/register'
import { Status } from 'models'

interface Props {
  onSubmit?: SubmitHandler<RegisterFormRequest>
  isSubmitting?: boolean
  disabled: boolean
}

//TODO, need to refactor  map DoubleInputContainer
const RegisterForm: VoidFunctionComponent<Props> = ({ onSubmit, isSubmitting, disabled }) => {
  const { t } = useTranslation<string>()
  const status = useAppSelector(seleckRegisterStatus)
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({ resolver: yupResolver(registerSchema) })

  const registerFields = Object.entries(RegisterFormFields)

  return (
    <Form name="register" noValidate onFinish={handleSubmit(form => onSubmit(form))}>
      <DoubleInputContainer id="DoubleInputContainer">
        <Input
          containerClassName="input-code"
          errorClassName="ant-form-item-has-error"
          control={control}
          name={'firstname'}
          placeholder={t(registerFields[0][1].placeholder)}
          error={errors[registerFields[0][0]]?.message}
          type={registerFields[0][1].type}
          id={generateFieldUuid('firstname')}
        />
        <Input
          containerClassName="input-code"
          errorClassName="ant-form-item-has-error"
          control={control}
          name={'lastname'}
          placeholder={t(registerFields[1][1].placeholder)}
          error={errors[registerFields[1][0]]?.message}
          type={registerFields[1][1].type}
          id={generateFieldUuid('lastname')}
        />
      </DoubleInputContainer>
      <DoubleInputContainer id="DoubleInputContainer">
        <Input
          containerClassName="input-code"
          errorClassName="ant-form-item-has-error"
          control={control}
          name={'birth_year'}
          placeholder={t(registerFields[2][1].placeholder)}
          error={errors[registerFields[2][0]]?.message}
          type={registerFields[2][1].type}
          id={generateFieldUuid('birth_year')}
        />
        <Input
          containerClassName="input-code"
          errorClassName="ant-form-item-has-error"
          control={control}
          name={'address'}
          placeholder={t(registerFields[3][1].placeholder)}
          error={errors[registerFields[3][0]]?.message}
          type={registerFields[3][1].type}
          id={generateFieldUuid('address')}
        />
      </DoubleInputContainer>
      <DoubleInputContainer id="DoubleInputContainer">
        <Input
          containerClassName="input-code"
          errorClassName="ant-form-item-has-error"
          control={control}
          name={'city'}
          placeholder={t(registerFields[4][1].placeholder)}
          error={errors[registerFields[4][0]]?.message}
          type={registerFields[4][1].type}
          id={generateFieldUuid('city')}
        />
        <Input
          containerClassName="input-code"
          errorClassName="ant-form-item-has-error"
          control={control}
          name={'zip_code'}
          placeholder={t(registerFields[5][1].placeholder)}
          error={errors[registerFields[5][0]]?.message}
          type={registerFields[5][1].type}
          id={generateFieldUuid('zip_code')}
        />
      </DoubleInputContainer>
      <DoubleInputContainer id="DoubleInputContainer">
        <Input
          containerClassName="input-code"
          errorClassName="ant-form-item-has-error"
          control={control}
          name={registerFields[6][0]}
          placeholder={t(registerFields[6][1].placeholder)}
          error={errors[registerFields[6][0]]?.message}
          type={registerFields[6][1].type}
          id={generateFieldUuid('emailAddress')}
        />
        <Input
          containerClassName="input-code"
          errorClassName="ant-form-item-has-error"
          control={control}
          name={registerFields[7][0]}
          placeholder={t(registerFields[7][1].placeholder)}
          error={errors[registerFields[7][0]]?.message}
          type={registerFields[7][1].type}
          id={generateFieldUuid('phoneNumber')}
        />
      </DoubleInputContainer>
      <p>{t('createPasswordForMuseumCard')}</p>
      <DoubleInputContainer id="DoubleInputContainer">
        <Input
          containerClassName="input-code"
          errorClassName="ant-form-item-has-error"
          control={control}
          name={registerFields[8][0]}
          placeholder={t(registerFields[8][1].placeholder)}
          error={errors[registerFields[8][0]]?.message}
          type={registerFields[8][1].type}
          id={generateFieldUuid('password')}
        />
        <Input
          containerClassName="input-code"
          errorClassName="ant-form-item-has-error"
          control={control}
          name={registerFields[9][0]}
          placeholder={t(registerFields[9][1].placeholder)}
          error={errors[registerFields[9][0]]?.message}
          type={registerFields[9][1].type}
          id={generateFieldUuid('confirmPassword')}
        />
      </DoubleInputContainer>

      <Form.Item>
        <Button
          id={generateFieldUuid('continue')}
          disabled={!disabled || status === Status.PENDING}
          className="fourteen wide computer twelve wide mobile centered column alignText"
          style={{ textAlign: 'center', marginTop: 20 }}
        >
          {status === Status.PENDING ? <SpineStyled size="small" /> : t('continue').toUpperCase()}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default RegisterForm

const SpineStyled = styled(Spin)`
  .ant-spin-dot {
    width: 45px !important;
    height: 45px !important;
  }
  .ant-spin-dot-item {
    width: 25px !important;
    height: 25px !important;
    background-color: ${colors.grey} !important;
  }
`
