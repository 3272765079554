import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import codeCheckReducer from './codeCheck'
import registerReducer from './register'

export const store = configureStore({
  reducer: {
    codeCheck: codeCheckReducer,
    register: registerReducer,
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
