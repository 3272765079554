export const cardNumberPattern = (number: string): string => {
  let formattedText = number.split(' ').join('')
  if (!!formattedText) {
    formattedText = formattedText.match(new RegExp('.{1,4}', 'g')).join(' ')
  }
  return formattedText
}

export const passwordValidator = /^\+(?:\d{8}|\d{11}|\d{13})$/

export const generateFieldUuid = (name: string, secondPart: any = 'field'): string => {
  return `${name}-${secondPart}`
}