import { Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { EnterCustomerNumber } from 'Components/EnterCustomerNumber'
import { Register } from 'Components/Register'
import ThankYou from 'Components/ThankYou'
const Routes: React.FunctionComponent = () => {
  return (
    <Switch>
      <Suspense fallback={<div>Loading...</div>}>
        <Route exact path="/">
          <Redirect to={{ pathname: '/home' }} />
        </Route>
        <Route exact path="/home">
          <EnterCustomerNumber />
        </Route>
        <Route exact path="/register">
          <Register />
        </Route>
        <Route exact path="/thank-you">
          <ThankYou />
        </Route>
      </Suspense>
    </Switch>
  )
}

export default Routes
