import React from 'react'
import styled from 'styled-components'
import Text, { TextType } from '../UiKit/Text'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { colors } from '../../constants/colors'
import { generateFieldUuid } from 'utils'

const ReturnButton: React.FunctionComponent = () => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <Container onClick={history.goBack} id={generateFieldUuid('return')}>
      <Text className="button-text" type={TextType.BOLD}>
        {'< ' + t('return').toLocaleUpperCase()}
      </Text>
    </Container>
  )
}

const Container = styled.button`
  background-color: ${colors.white};
  border: 0;
  .button-text {
    background-color: ${colors.white};
    color: ${colors.primary};
  }
  &:focus {
    outline-color: ${colors.white};
    outline: 0;
  }
  cursor: pointer;
`

export default ReturnButton
