import React from 'react'
import { useTranslation } from 'react-i18next'
import Text from '../UiKit/Text'
import ErrorMessage from '../UiKit/ErrorMessage'
import { colors } from 'constants/colors'

interface Props {
  errorMessage: any
  messageCode?: string
}
const ErrorMessageForm: React.FunctionComponent<Props> = ({ errorMessage, messageCode }) => {
  const { t } = useTranslation<string>()

  const returnErrorMessage = (message: string) => {
    return (
      <Text>
        {message}
        <a
          target="_blank"
          rel="noopener noreferrer"
          className="text-decoration"
          href={'https://gift4you.fi/Landing/Contact'}
          style={{color: colors.primary}}
        >
          <Text className="hyperlink-text"> {t('customerService')} </Text>
        </a>
        info@gift4you.fi, +358440883364. {t('customerServiceHours')}
      </Text>
    )
  }
  if (errorMessage?.errors) {
    return (
      <ErrorMessage>
        {Object.keys(errorMessage?.errors).map(label => {
          if (errorMessage?.errors[label][0] === 'The gift4you code has already been taken.') {
            return returnErrorMessage(t('giftCodeHasAlreadyBeenUsed'))
          } else return <p>{errorMessage?.errors[label][0]}</p>
        })}
      </ErrorMessage>
    )
  } else {
    switch (errorMessage?.response_code_from_gift4you) {
      case '0':
        //product not found, not sold
        return <ErrorMessage>{returnErrorMessage(t('couldNotActivateCode'))}</ErrorMessage>
      case '2':
        //product already redeemed : example GYMK20736367
        return <ErrorMessage>{returnErrorMessage(t('giftCodeHasAlreadyBeenUsed'))}</ErrorMessage>
      default:
        return (
          //3 product voided, expired card, ex: GYMK27069901
          <ErrorMessage>{returnErrorMessage(t('giftCodeIsNoLongerActiveBecause'))}</ErrorMessage>
        )
    }
  }
}

export default ErrorMessageForm
