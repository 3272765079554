import { SubmitHandler, useForm } from 'react-hook-form'
import { VoidFunctionComponent } from 'react'
import { Form } from 'antd'
import Input from '../UiKit/Input'
import { yupResolver } from '@hookform/resolvers/yup'
import cardNumberSchema from '../../models/schemas/cardNumberSchema'
import { InputType } from '../../models'
import { useTranslation } from 'react-i18next'
import { CheckCodeFormField } from './CheckCodeFormField'
import { CheckCodeRequest } from '../../models/services'
import Button from '../UiKit/Button'
import ClipLoader from 'react-spinners/ClipLoader'
import { colors } from '../../constants/colors'
import { Status } from 'models'
import { generateFieldUuid } from 'utils'

interface Props {
  onSubmit: SubmitHandler<CheckCodeRequest>
  status: Status
}

const CardNumberForm: VoidFunctionComponent<Props> = ({ onSubmit, status }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<any>({ resolver: yupResolver(cardNumberSchema) })
  const { t } = useTranslation<string>()
  const checkCodeFields = Object.entries(CheckCodeFormField)
  return (
    <Form onFinish={handleSubmit(onSubmit)} noValidate>
      <Input
        maxLength={12}
        inputClassName="centered-input"
        errorClassName="ant-form-item-has-error"
        containerClassName="input-code padding-horizontal"
        control={control}
        name="gift4you_code"
        placeholder={t(CheckCodeFormField.gift4you_code.placeholder)}
        type={InputType.TEXT}
        error={errors[checkCodeFields[0][0]]?.message}
        id={generateFieldUuid('gift4you_code')}
      />
      <Form.Item>
        <Button
          id={generateFieldUuid('continue')}
          className="fourteen wide computer twelve wide mobile centered column alignText"
          style={{ textAlign: 'center', marginTop: 20 }}
        >
          {status === Status.PENDING ? <ClipLoader size={20} color={colors.white} /> : t('continue').toUpperCase()}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default CardNumberForm
