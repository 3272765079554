const en = Object.freeze({
  registerAsAMuseumCard: 'Redeem your Gift4You gift card',
  enterYourCustomerNumber1:
    'Enter the gift code from your Gift4You card. You can find the code either in the top-right corner on the back side of the gift card or in a separate receipt.',
  enterYourCustomerNumber2:
    'Click “continue” and you can register as a Museum Card customer. We will send you a plastic card with your name on it based on the registration information.  ',
  enterYourCustomerNumber3:
    'Whilst waiting for the card, you can visit museums with your customer number, which you will receive immediately by email and SMS after registration, or by using the Museum Card application. Your Museum Card’s validity period begins from the first museum visit.',
  museumCardNumber12Digits: 'Enter a gift code',
  continue: 'Continue',
  didYouBuyTheMuseumCardAsGift: 'Did you buy the Museum Card as a gift?',
  weRecommedThatTheReceivers:
    'We recommend that the receivers of the gifts always register as users by themselves. On the basis of the registration information, the actual Museum Card is made and posted to the receivers and an automatic confirmation of the registration is also sent to them by email and text message.',
  IHaveReadAndAccept: 'I have read and I accept the ',
  termsAndConditions: 'terms and conditions of the Museum Card.',
  termsAndConditions2: '',
  receiveTheNewsletter: 'I want to receive the newsletter. The subscription can be cancelled at any time.',
  iHaveCampaignCode: 'I have a campaign code',
  fillInTheInformationForTheDelivery:
    'Fill in the information for the delivery of your permanent Museum Card. Your personal card will arrive at the address you have given in 3-4 weeks from the moment of your registration. The cards are produced automatically on the basis of the information given, therefore the information cannot be changed afterwards.',
  firstName: 'First name*',
  lastName: 'Last name*',
  yearOfbirth: 'Year of birth*',
  streetAddress: 'street address*',
  town: 'Town*',
  zipCode: 'Zipcode*',
  emailAddress: 'Email address*',
  phoneNumber: 'Phone',
  password: 'Password*',
  confirmPassword: 'Confirm password',
  thankYouForRegistering: 'Thank you for registering!',
  weWillDeliverYourPersonalPermenet:
    'We will deliver your personal permanent Museum card provided with your name by post within 3-4 weeks. Until then you can pop in at a museum by presenting your customer number at the ticket desk. Be prepared also to confirm your identity. You can check the validity period of your Museum Card as well as your other information at the',
  ownMuseumCardPage: 'Own Museum Card page (in Finnish)',
  museumTripIsMore:
    'Museum trip is more fun with friends. Tell your friends about your cultural trip and invite them to come with you',
  followMuseumCardFacebookInstagramTwitter: 'Follow Museum Card on Facebook, Instagram and Twitter.',
  downloadTheMuseumCardApp: 'Museum Card App',
  getToKnow: 'GET TO KNOW 300 MUSEUM CARD MUSEUMS',
  museumCardIsAnEntrance: 'Museum Card is an entrance ticket to 300 museums around Finland, valid during 12 months.',
  listOfMuseumCardMuseums: 'List of Museum card museums >',
  browseExhibitionsAndEvents: 'BROWSE EXHIBITIONS AND EVENTS',
  acquaintYourself: 'Acquaint yourself with ongoing and coming exhibitions and events at Museum Card museums.',
  listOfExhibitions: 'List of exhibitions >',
  listOfEvents: 'List of events >',
  seekOutMuseumsOnTheMap: 'SEEK OUT MUSEUMS ON THE MAP',
  planYourMuseumTrip:
    'Plan your museum trip and seek out the nearest Museum Card museums on the basis of your location.',
  IfYouReceivedaMuseumCard:
    'If you received the Museum Card as a gift, but it is not a Gift4You gift card, follow these steps.',
  mapSearch: 'Map search >',
  createPasswordForMuseumCard:
    'Create password for Museum Card customer page. (min. 8 digits). On customer page you can view your museum visits and renew your card. You can change the password later.',
  return: 'Return',
  giftCodeIsNotSoldAccordingPaytjek: 'Gift code is not sold according to Paytjek system:',
  couldNotActivateCode: 'Could not activate the gift code.Please contact Gift4You’s ',
  customerService: 'customer service',
  customerServiceHours: 'Customer service hours: Weekdays from 9 am to 3 pm.',
  giftCodeIsNotValidAnymore: 'Gift code is not valid anymore (was sold over 12 months ago):',
  giftCodeIsNoLongerActiveBecause:
    'The gift card is no longer active because it was bought over 12 months ago. For more information, contact Gift4You at',
  giftCodeHasBeenActivatedAlready: 'Gift code has been activated already:',
  giftCodeHasAlreadyBeenUsed: 'The gift code has already been used. For more information, contact Gift4You’s ',
  "listOfMuseumHyperlink": "https://museot.fi/searchmuseums/",
  "listOfExebitionsHyperlink": "https://museot.fi/exhibitions/",
  "listOfEventsHyperlink": "https://museot.fi/events/",
  "firstNameRequired": "first name is a required field",
  "lastNameRequired": "last name is a required field",
  "streetAddressRequired": "street address is a required field",
  "passwordRequired": "password is a required field",
  "password8Characters": "Password must be at least 8 characters",
  "confirmPasswordRequired": "confirm password is a required field",
  "confirmPassValidation": "Passwords must match",
  "zipCodeRequired": "zip code is a required field",
  "birthYearRequired": "birth year is a required field",
  "cityRequired": "city is a required field",
  "emailRequired": "email is a required field",
  cardLength: 'Card number must be  12 characters',
})

export default en
