import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../index'
import { checkCodeRequestAsync } from './asyncActions'
import { Status } from 'models'
import { CheckCodeRequest } from 'models/services/CheckCodeRequest'

export interface CheckCodeState {
  is_fetching: boolean
  data: any
  status: Status
  error: any
  giftCode: CheckCodeRequest
}

const initialState: CheckCodeState = {
  is_fetching: false,
  data: null,
  status: Status.IDLE,
  error: null,
  giftCode: null,
}

export const checkCode = createSlice({
  name: 'checkCode',
  initialState,
  reducers: {
    setGiftCode: (state, action: PayloadAction<CheckCodeRequest>) => {
      state.giftCode = action.payload
    },
  },

  extraReducers: builder => {
    builder
      .addCase(checkCodeRequestAsync.pending, state => {
        state.status = Status.PENDING
      })
      .addCase(checkCodeRequestAsync.fulfilled, (state, action) => {
        state.status = Status.SUCCEEDED
        state.data = action.payload
      })
      .addCase(checkCodeRequestAsync.rejected, (state, action) => {
        state.status = Status.FAILED
        state.error = action.payload ?? 'Unknown Error'
      })
  },
})

export const { setGiftCode } = checkCode.actions

export const seleckCheckcodeStatus = (state: RootState): CheckCodeState['status'] => state.codeCheck.status
export const selectData = (state: RootState): any => state.codeCheck.giftCode
export const selectSucessData  = (state:RootState) :any => state.codeCheck.data
export const selectErrorData = (state: RootState): CheckCodeState['error'] => state.codeCheck.error
export default checkCode.reducer
