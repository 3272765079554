const fi = Object.freeze({
  registerAsAMuseumCard: 'Lunasta Gift4You-lahjakorttisi',
  enterYourCustomerNumber1:
    'Syötä alle lahjakoodi Gift4You-lahjakortistasi. Lahjakoodi löytyy joko lahjakortin takapuolen oikeasta yläkulmasta tai erilliseltä kuitilta.',
  enterYourCustomerNumber2:
    'Paina “Jatka” ja pääset rekisteröitymään Museokortin asiakkaaksi. Rekisteröintitietojen perusteella lähetämme nimelläsi varustetun muovikortin. ',
  enterYourCustomerNumber3:
    'Korttia odottaessa, voit vierailla museoissa asiakasnumerolla, jonka saat välittömästi rekisteröinnin jälkeen sähköpostilla ja tekstiviestillä tai ottamalla käyttöön Museokortti-sovelluksen. Museokorttisi voimassaolo alkaa ensimmäisestä museokäynnistä.',
  museumCardNumber12Digits: 'Syötä lahjakoodi',
  continue: 'Jatka',
  didYouBuyTheMuseumCardAsGift: 'Ostitko Museokortin lahjaksi?',
  weRecommedThatTheReceivers:
    'Suosittelemme, että lahjansaaja kirjautuu käyttäjäksi aina itse. Kirjautumistietojen perusteella hänelle valmistetaan ja postitetaan varsinainen Museokortti ja kirjautumisesta lähtee hänelle myös automaattinen kuittaus sähköpostilla ja tekstiviestillä.',
  IHaveReadAndAccept: `Olen lukenut ja hyväksynyt Museokortin `,
  termsAndConditions: 'käyttöehdot',
  termsAndConditions2: '',
  receiveTheNewsletter: 'Haluan saada uutiskirjeen. Tilauksen voi perua milloin vain.',
  iHaveCampaignCode: 'Minulla on kampanjakoodi',
  fillInTheInformationForTheDelivery:
    'Täytä tiedot varsinaisen Museokorttisi toimittamista varten. Henkilökohtainen korttisi saapuu antamaasi osoitteeseen 3-4 viikossa rekisteröitymisestäsi. Kortit valmistetaan annettujen tietojen perusteella automaattisesti, joten tietoja ei voi muuttaa jälkeenpäin.',
  firstName: 'Etunimi*',
  lastName: 'Sukunimi*',
  yearOfbirth: 'Syntymävuosi*',
  streetAddress: 'Lähiosoite*',
  town: 'Kaupunki*',
  zipCode: 'Postinumero*',
  emailAddress: 'Sähköposti*',
  phoneNumber: 'Puhelin',
  password: 'Salasana*',
  confirmPassword: 'Vahvista salasana',
  thankYouForRegistering: 'Kiitos rekisteröitymisestäsi!',
  weWillDeliverYourPersonalPermenet:
    'Toimitamme nimelläsi varustetun henkilökohtaisen Museokorttisi postitse 3-4 viikon kuluessa. Siihen asti voit poiketa museoon esittämällä asiakasnumerosi lipputiskillä. Varaudu todistamaan myös henkilöllisyytesi. Museokorttisi voimassaoloajan ja muut tietosi tarkistat ',
  ownMuseumCardPage: 'Oma Museokorttisivu (suomeksi)',
  museumTripIsMore:
    'Museokierroksella on hauskempaa ystävien seurassa. Kerro kulttuurimatkastasi ja houkuttele kaverisikin mukaan!',
  followMuseumCardFacebookInstagramTwitter: 'Seuraa Museokorttia Facebookissa, Instagramissa ja Twitterissä.',
  downloadTheMuseumCardApp: 'Lataa Museokortti-sovellus',
  getToKnow: 'TUTUSTU 300 MUSEOKORTTI-KOHTEESEEN',
  museumCardIsAnEntrance: 'Museokortti on vuoden voimassaoleva pääsylippu 300 museoon ympäri Suomen.',
  listOfMuseumCardMuseums: 'Lista Museokortti-kohteista >',
  browseExhibitionsAndEvents: 'SELAA NÄYTTELYITÄ JA TAPAHTUMIA',
  acquaintYourself: 'Tutustu meneillään oleviin ja tuleviin näyttelyihin sekä tapahtumiin Museokortti-kohteissa.',
  listOfExhibitions: 'Lista näyttelyistä >',
  listOfEvents: 'Lista tapahtumista >',
  seekOutMuseumsOnTheMap: 'ETSI MUSEOT KARTALTA',
  planYourMuseumTrip: 'Suunnittele museokierroksesi ja etsi lähimmät Museokortti-kohteet sijaintisi perusteella.',
  mapSearch: 'Karttahaku >',
  IfYouReceivedaMuseumCard: 'Jos sait Museokortin lahjaksi, mutta se ei ole Gift4You-lahjakortti, toimi näin',
  createPasswordForMuseumCard:
    'Luo salasana (min. 8 merkkiä) Oma Museokortti -asiakassivulle. Sivulla näet museokäyntisi ja jatkat korttisi voimassaoloa. Voit vaihtaa salasanan myöhemmin.',
  return: 'Takaisin',
  giftCodeIsNotSoldAccordingPaytjek: 'Gift code is not sold according to Paytjek system:',
  couldNotActivateCode: 'Lahjakoodia ei voi aktivoida. Ota yhteyttä Gift4You:n',
  customerService: ' asiakaspalveluun ',
  customerServiceHours: 'Asiakaspalvelun aukioloaika: Arkipäivisin klo 9-15.',
  giftCodeIsNotValidAnymore: 'Gift code is not valid anymore (was sold over 12 months ago):',
  giftCodeIsNoLongerActiveBecause:
    'Lahjakortti ei ole enää käytettävissä, koska se on ostettu yli 12 kk sitten. Lisätietoa Gift4You:n',
  giftCodeHasBeenActivatedAlready: 'Gift code has been activated already:',
  giftCodeHasAlreadyBeenUsed: 'Lahjakoodi on jo käytetty. Lisätietoa Gift4You:n',
  "listOfMuseumHyperlink": "https://museot.fi/museohaku/",
  "listOfExebitionsHyperlink": "https://museot.fi/nayttelykalenteri/",
  "listOfEventsHyperlink": "https://museot.fi/tapahtumat/",
  "firstNameRequired": "Etunimi on pakollinen kenttä",
  "lastNameRequired": "Sukunimi on pakollinen kenttä",
  "streetAddressRequired": "Katuosoite on pakollinen kenttä",
  "passwordRequired": "Salasana on pakollinen kenttä",
  "password8Characters": "Salasanassa on oltava vähintään 8 merkkiä",
  "confirmPasswordRequired": "Vahvista salasana on pakollinen kenttä",
  "confirmPassValidation": "Salasanojen täytyy täsmätä",
  "zipCodeRequired": "Postinumero on pakollinen kenttä",
  "birthYearRequired": "Syntymävuosi on pakollinen kenttä",
  "cityRequired": "Kaupunki on pakollinen kenttä",
  "emailRequired": "Sähköposti on pakollinen kenttä",
  cardLength: 'Kortin numerossa on oltava 12 merkkiä',
})

export default fi
