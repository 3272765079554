import React from 'react'
import styled from 'styled-components'

const ErrorMessage: React.FunctionComponent = ({ children }) => {
  return (
    <Container className="ui transition icon error message ">
      <i className="warning icon"></i>
      <div>{children}</div>
    </Container>
  )
}

const Container = styled.div`
  .flexable {
    display: flex;
  }
`

export default ErrorMessage
