import { createAsyncThunk } from '@reduxjs/toolkit'
import { AppService, CheckCodeRequest } from '../../models/services'
import { handleApiCallError } from 'store/helper'

export const checkCodeRequestAsync = createAsyncThunk<void, CheckCodeRequest, { rejectValue: string }>(
  '/web/user/gift4you/check',
  async (body, { rejectWithValue }) => {
    try {
      return await AppService.checkCode(body)
    } catch (e) {
      return handleApiCallError(e, rejectWithValue)
    }
  }
)
