import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../index'
import { registerRequestAsync } from './asyncActions'
import { Status } from 'models'

export interface RegisterState {
  is_fetching: boolean
  error: any
  status: Status
}

const initialState: RegisterState = {
  is_fetching: false,
  status: Status.IDLE,
  error: null,
}

export const register = createSlice({
  name: 'register',
  initialState,
  reducers: {
    resetError: (state) => {
      state.error = null
      state.status = Status.IDLE
    }
  },
  extraReducers: builder => {
    builder
      .addCase(registerRequestAsync.pending, state => {
        state.status = Status.PENDING
      })
      .addCase(registerRequestAsync.fulfilled, state => {
        state.status = Status.SUCCEEDED
      })
      .addCase(registerRequestAsync.rejected, (state, action) => {
        state.status = Status.FAILED
        state.error = action.payload ?? 'Unknown Error'
      })
  },
})
export const { resetError } = register.actions


export const seleckRegisterStatus = (state: RootState): RegisterState['status'] => state.register.status
export const selectErrorData = (state: RootState): RegisterState['error'] => state.register.error

export default register.reducer
