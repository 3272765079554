import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { generateFieldUuid } from 'utils'

enum Language {
  FINNISH = 'fi',
  ENGLISH = 'en',
  SWEDISH = 'sv',
}

export const ChangeLanguage: React.FunctionComponent = () => {
  const { i18n } = useTranslation<string>()

  return (
    <Container className="atom-languageSelector" id="languageSelectMenu">
      <ul className="atom-languageSelector__choices">
        <li className="atom-languageSelector__singleChoice -selected">
          <button
            id={generateFieldUuid(Language.FINNISH)}
            onClick={() => i18n.changeLanguage(Language.FINNISH)}
            className={
              i18n.language === Language.FINNISH ? `select-language-button-selected` : `select-language-button`
            }
          >
            fi
          </button>
        </li>
        <li className="atom-languageSelector__singleChoice">
          <button
            id={generateFieldUuid(Language.ENGLISH)}
            onClick={() => i18n.changeLanguage(Language.ENGLISH)}
            className={
              i18n.language === Language.ENGLISH ? `select-language-button-selected` : `select-language-button`
            }
          >
            en
          </button>
        </li>
        <li className="atom-languageSelector__singleChoice">
          <button
            id={generateFieldUuid(Language.SWEDISH)}
            onClick={() => i18n.changeLanguage(Language.SWEDISH)}
            className={
              i18n.language === Language.SWEDISH ? `select-language-button-selected` : `select-language-button`
            }
          >
            sv
          </button>
        </li>
      </ul>
    </Container>
  )
}
const Container = styled.div`
  .atom-languageSelector {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  @media (min-width: 1024px) {
    .atom-languageSelector {
      width: 100%;
      position: absolute;
      bottom: 2.5rem;
    }
  }
  @media (min-width: 1140px) {
    .atom-languageSelector {
      bottom: 3rem;
    }
  }
  .atom-languageSelector__choices {
    display: flex;
    justify-content: center;
  }
  .atom-languageSelector__singleChoice {
    height: 2.5rem;
    width: 2.5rem;
    margin: 0 0.375rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  .atom-languageSelector__singleChoice:before {
    content: '/';
    position: absolute;
    left: -0.5rem;
    color: #ffdbd8;
  }
  .atom-languageSelector__singleChoice a {
    height: 2.5rem;
    width: 2.5rem;
    font-family: 'RightGrotesk-SpatialDark', sans-serif;
    color: #ffdbd8;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.25s ease;
  }
  .atom-languageSelector__singleChoice a:focus,
  .atom-languageSelector__singleChoice a:hover {
    color: #ff598f;
  }
  .atom-languageSelector__singleChoice.-selected a {
    color: #ff598f;
  }
  .atom-languageSelector__singleChoice:first-child:before {
    content: none;
  }

  .select-language-button-selected {
    font-weight: bold;
    background-color: transparent;
    border: 0;
    height: 2.5rem;
    width: 2.5rem;
    font-family: 'RightGrotesk-SpatialDark', sans-serif;
    color: #ff598f;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.25s ease;
  }
  .select-language-button {
    font-weight: bold;
    background-color: transparent;
    border: 0;
    height: 2.5rem;
    width: 2.5rem;
    cursor: pointer;
    font-family: 'RightGrotesk-SpatialDark', sans-serif;
    color: #ffdbd8;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.25s ease;
  }
  .select-language-button:focus {
    outline: 0;
  }

  .select-language-button:hover {
    color: #ff598f;
  }

  .select-language-button-selected:focus {
    outline: 0;
  }
`
