import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import facebook from '../../assets/icons/Facebook_Black.png'
import instagram from '../../assets/icons/Instagram_Black.png'
import twitter from '../../assets/icons/Twitter_Black.png'
import Header from '../UiKit/Header'
import Text, { TextType } from '../UiKit/Text'
import HyperLinkText from './HyperLinkText'

const socialMedia = [
  { icon: facebook, hyperlink: 'https://www.facebook.com/museokortti' },
  { icon: instagram, hyperlink: 'https://www.instagram.com/museokortti/' },
  { icon: twitter, hyperlink: 'https://twitter.com/museokortti' },
]

const mediaMapper = (t: TFunction): any =>
  socialMedia.map(item => (
    <a key={item.icon} target="_blank" rel="noopener noreferrer" href={item.hyperlink}>
      <img src={item.icon} alt={t('basket')} className="social-media-img" />
    </a>
  ))

const ThankYou: React.FunctionComponent = () => {
  const { t, i18n } = useTranslation()
  const [lang, setLang] = useState('')

  useEffect(() => {
    setLang(i18n.language.replace('sv', 'se'))
  }, [i18n.language])

  return (
    <Container>
      <div className="row">
        <div className="main-cointainer area">
          <div className="margin-1 aligned-container" id="contentBox">
            <Header title={t('thankYouForRegistering')} />
            <br />
            <Text className="flexable-text">{t('weWillDeliverYourPersonalPermenet')}</Text>
            <HyperLinkText text="ownMuseumCardPage" hyperlink={'https://asiakassivu.museokortti.fi/login'} />
            <br />
            <Text>{t('museumTripIsMore')}</Text>
            <br />
            {mediaMapper(t)}
            <div className="standout-text">
              <Text className="single-line-text">{t('followMuseumCardFacebookInstagramTwitter')}</Text>
              <Text className="single-line-text" type={TextType.BOLD}>
                #museokortti #museokierros @museokortti
              </Text>
            </div>
            <HyperLinkText text="downloadTheMuseumCardApp" hyperlink={'https://museot.fi/sovellus'} />
            <div className="standout-text">
              <Text className="single-line-text" type={TextType.BOLD}>
                {t('getToKnow').toUpperCase()}
              </Text>
              <Text className="single-line-text">{t('museumCardIsAnEntrance')}</Text>
              <HyperLinkText text="listOfMuseumCardMuseums" hyperlink={t('listOfMuseumHyperlink')} />
            </div>
            <div className="standout-text">
              <Text className="single-line-text" type={TextType.BOLD}>
                {t('browseExhibitionsAndEvents').toUpperCase()}
              </Text>
              <Text className="single-line-text">{t('acquaintYourself')}</Text>
              <HyperLinkText text="listOfExhibitions" hyperlink={t('listOfExebitionsHyperlink')} />
              <HyperLinkText text="listOfEvents" hyperlink={t('listOfEventsHyperlink')} />
            </div>
            <div className="standout-text">
              <Text className="single-line-text" type={TextType.BOLD}>
                {t('seekOutMuseumsOnTheMap').toUpperCase()}
              </Text>
              <Text className="single-line-text">{t('planYourMuseumTrip')}</Text>
              <HyperLinkText text="mapSearch" hyperlink={`https://museot.fi/kulttuurikierros-kartta/karttahaku.php?lang=${lang}`} />
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media only screen and (max-width: 768px) {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  @media (min-width: 1200px) {
    padding-left: 0;
    padding-right: 0;
  }
  .aligned-container {
    margin: 1rem;
    padding: 0 3rem 2rem 3rem;
    @media only screen and (max-width: 768px) {
      padding: 0;
    }
  }
  .row {
    background-color: #f7f7f7;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: inherit;
    align-items: stretch;
    width: 100% !important;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .single-line-text {
    display: block;
  }
  .social-media-img {
    margin: 10px;
    height: auto;
    width: 35px;
  }
  .standout-text {
    margin: 1rem 0;
  }
  .text-decoration {
    text-decoration: none;
  }
  .line-break {
    margin-top: 10px;
  }
  .flexable-text {
    display: flex;
  }
`
export default ThankYou
