import { createSlice } from '@reduxjs/toolkit'
import type { AsyncThunk } from '@reduxjs/toolkit'
import { Status } from 'models'
import type { NormalState, ListState } from '../models/ApiState'

export function normalInitialStateBuilder<ItemType>(): NormalState<ItemType> {
  return {
    status: Status.IDLE,
    error: null,
    data: null,
  }
}

export function listInitialStateBuilder<ItemType>(): ListState<ItemType> {
  return {
    status: Status.IDLE,
    error: null,
    data: [],
  }
}

export function createSliceBuilder<ResourceType>(
  sliceName: string,
  asyncThunk: AsyncThunk<any, any, { rejectValue: string }>
) {
  const initialState = normalInitialStateBuilder<ResourceType>()
  return createSlice({
    name: sliceName,
    initialState,
    reducers: {},
    extraReducers: builder => {
      builder
        .addCase(asyncThunk.pending, state => {
          state.status = Status.PENDING
          state.error = null
        })
        .addCase(asyncThunk.fulfilled, (state, action) => {
          state.status = Status.SUCCEEDED
          state.data = action.payload
        })
        .addCase(asyncThunk.rejected, (state, action) => {
          state.status = Status.FAILED
          state.error = action.payload ?? 'Unknown Error'
        })
    },
  })
}
// TODO add a test for testing that data is filled in two type of response : action.payload.data || action.payload
export function listSliceBuilder<ResourceType>(
  sliceName: string,
  asyncThunk: AsyncThunk<any, any, { rejectValue: string }>
) {
  const initialState = listInitialStateBuilder<ResourceType>()
  return createSlice({
    name: sliceName,
    initialState,
    reducers: {},
    extraReducers: builder => {
      builder
        .addCase(asyncThunk.pending, state => {
          state.status = Status.PENDING
          state.error = null
        })
        .addCase(asyncThunk.fulfilled, (state, action) => {
          state.status = Status.SUCCEEDED
          state.data = action.payload.data || action.payload || []
        })
        .addCase(asyncThunk.rejected, (state, action) => {
          state.status = Status.FAILED
          state.error = action.payload ?? 'Unknown Error'
        })
    },
  })
}

export const handleApiCallError = (err: any, rejectWithValue: any) => {
  if (err?.body?.message) {
    return rejectWithValue(err.body)
  }
  const message: any = { message: err.message }
  return rejectWithValue(message)
}
