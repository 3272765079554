import * as yup from 'yup'
import { RegisterFormRequest } from '../services/RegisterFormRequest'
import { GeneralSchemaType } from './GeneralSchemaType'
// import { passwordValidator } from '../../utils'

const registerSchema = yup.object().shape<GeneralSchemaType<RegisterFormRequest>>({
  firstname: yup.string().required(`firstNameRequired`),
  lastname: yup.string().required(`lastNameRequired`),
  address: yup.string().required(`streetAddressRequired`),
  password: yup
    .string()
    .required('passwordRequired')
    .test('password-check', 'password8Characters', value => value && value.length >= 8),
  confirmPassword: yup
    .string()
    .required('confirmPasswordRequired')
    .test(
      'password-confirmation',
      'confirmPassValidation',
      (value, testCtx) => testCtx.parent.password && testCtx.parent.password === value
    ),
  zip_code: yup.string().required(`zipCodeRequired`),
  birth_year: yup.string().required(`birthYearRequired`),
  city: yup.string().required(`cityRequired`),
  email: yup.string().required(`emailRequired`),
  coupon_code: yup.string(),
  phone_number: yup.string(),
})

export default registerSchema
