import React from 'react'
import styled from 'styled-components'
import Text, { TextType } from '../UiKit/Text'
import { colors } from '../../constants/colors'

interface Props {
  children: React.ReactNode
}

const LinkText: React.FunctionComponent<Props> = ({ children }) => {
  return (
    <Container>
      <Text className="link-text" type={TextType.BIG_BOLD}>
        {children}
      </Text>
    </Container>
  )
}

const Container = styled.a`
  .link-text {
    background-color: ${colors.white};
    color: ${colors.primary};
  }
  cursor: pointer;
  display: block;
`

export default LinkText
