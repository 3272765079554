const sv = Object.freeze({
  registerAsAMuseumCard: 'Lös in ditt Gift4You-presentkort',
  enterYourCustomerNumber1:
    'Ange presentkoden från ditt Gift4You-presentkort nedan. Presentkoden finns antingen på presentkortets baksida i det övre högra hörnet eller på ett separat kvitto.',
  enterYourCustomerNumber2:
    'Klicka på ”Fortsätt” för att registrera dig som Museikortets kund. Utifrån registreringsuppgifterna skickar vi ett plastkort med ditt namn till dig.  ',
  enterYourCustomerNumber3:
    'Medan du väntar på ditt kort kan du besöka museer med kundnumret som du får direkt efter registreringen per e-post och som textmeddelande, eller genom att ta i bruk Museikortet-appen. Ditt Museikort börjar gälla från och med det första museibesöket.',
  museumCardNumber12Digits: 'Ange presentkod',
  didYouBuyTheMuseumCardAsGift: 'Köpte du Museikortet som gåva?',
  weRecommedThatTheReceivers:
    'Vi rekommenderar att gåvomottagaren alltid själv registrerar sig som användare. På basen av registreringsuppgifterna framställs och postas för hen det egentliga Museikortet och av registreringen får hen också en automatisk kvittering per email och textmeddelande.',
  IHaveReadAndAccept: 'Jag har läst och godkänner  ',
  termsAndConditions: 'villkoren ',
  termsAndConditions2: ' för Museikortet.',
  receiveTheNewsletter: 'Jag vill få nyhetsbrevet. Prenumerationen kan avslutas när som helst.',
  iHaveCampaignCode: 'Ange en eventuell kampanjkod här eller lämna fältet tomt',
  fillInTheInformationForTheDelivery:
    'Fyll i uppgifterna för levereringen av ditt permanent Museikort. Ditt personliga kort anländer till den adress du angivit inom 1–3 veckor från registreringen. Korten framställs automatiskt på basen av de angivna uppgifterna därför kan uppgifterna inte ändras i efterhand.',
  firstName: 'Förnamn*',
  lastName: 'Efternamn*',
  yearOfbirth: 'Födelseår*',
  streetAddress: 'Näradress*',
  town: 'Stad*',
  zipCode: 'Postnummer*',
  emailAddress: 'Emailadress*',
  phoneNumber: 'Telefon',
  password: 'Lösenord*',
  confirmPassword: 'Bekräfta lösenord',
  thankYouForRegistering: 'Tack för att du har registrerat dig!',
  weWillDeliverYourPersonalPermenet:
    'Vi levererar per post ett personligt Museikort försett med ditt namn inom 1–3 veckor. Till dess kan du sticka dig in på ett museum genom att visa upp ditt kundnummer vid biljettdisken. Var beredd att också styrka din identitet. Giltighetstiden för Museikortet och dina andra uppgifter granskar du på kundsidan ',
  ownMuseumCardPage: 'Eget museumskortsida (på finska)',
  museumTripIsMore:
    'En museirundtur är roligare tillsammans med vänner. Berätta om din kulturresa och locka också dina vänner med!',
  followMuseumCardFacebookInstagramTwitter: 'Följ Museikortet på Facebook, Instagram och Twitter',
  downloadTheMuseumCardApp: 'Museikortet Appen',
  getToKnow: 'BEKANTA DIG MED 300 MUSEIKORTMÅL',
  museumCardIsAnEntrance:
    'Museikortet är en inträdesbiljett till nästan 300 museer runt om i Finland som är i kraft ett år.',
  listOfMuseumCardMuseums: 'Lista över Museikortmålen >',
  browseExhibitionsAndEvents: 'BLÄDDRA BLAND UTSTÄLLNINGAR OCH EVENEMANG',
  acquaintYourself: 'Bekanta dig med utställningar och evenemang som pågår och som är på kommande på Museikortmålen.',
  listOfExhibitions: 'Lista över utställningar >',
  listOfEvents: 'Lista över evenemang >',
  seekOutMuseumsOnTheMap: 'SÖK FRAM MUSEERNA PÅ KARTAN',
  planYourMuseumTrip: 'Planera din museirunda och sök fram de närmaste Museikortmålen på basen av var du befinner dig.',
  mapSearch: 'Kartsökning >',
  IfYouReceivedaMuseumCard:
    'Om du har fått ett Museikort som gåva, men det är inte ett Gift4You-presentkort, gör så här.',
  createPasswordForMuseumCard:
    'Skapa lösenord för Museikort kundsidan (minst 8 siffror). På kundsidan kan du se din museibesökarna och förnya kortet. Du kan ändra lösenordet senare.',
  return: 'Tillbaka',
  giftCodeIsNotSoldAccordingPaytjek: 'Gift code is not sold according to Paytjek system:',
  couldNotActivateCode: 'Det gick inte att aktivera presentkoden. Kontakta Gift4Yous',
  customerService: ' kundtjänst ',
  customerServiceHours: 'Kundtjänsten är öppen: Vardagar kl. 9–15.',
  giftCodeIsNotValidAnymore: 'Gift code is not valid anymore (was sold over 12 months ago):',
  giftCodeIsNoLongerActiveBecause:
    'Presentkortet är inte längre giltigt, eftersom det köptes för över 12 månader sedan. Du får mer information från Gift4Yous',
  giftCodeHasBeenActivatedAlready: 'Gift code has been activated already:',
  giftCodeHasAlreadyBeenUsed: 'Presentkoden har redan använts. Du får mer information från Gift4Yous',
  continue: 'Fortsätt',
  "listOfMuseumHyperlink": "https://museot.fi/sokmuseer/",
  "listOfExebitionsHyperlink": "https://museot.fi/utstallningar/",
  "listOfEventsHyperlink": "https://museot.fi/evenemang/",
  "firstNameRequired": "förnamn är ett obligatoriskt fält",
  "lastNameRequired": "efternamn är ett obligatoriskt fält",
  "streetAddressRequired": "gatuadress är ett obligatoriskt fält",
  "passwordRequired": "lösenord är ett obligatoriskt fält",
  "password8Characters": "Lösenordet måste bestå av minst 8 tecken",
  "confirmPasswordRequired": "bekräfta lösenord är ett obligatoriskt fält",
  "confirmPassValidation": "lösenorden måste vara lika",
  "zipCodeRequired": "postnummer är ett obligatoriskt fält",
  "birthYearRequired": "födelseår är ett obligatoriskt fält",
  "cityRequired": "stad är ett obligatoriskt fält",
  "emailRequired": "mejla ett obligatoriskt fält",
  cardLength: 'Kortnummer måste vara 12 tecken',
})

export default sv
