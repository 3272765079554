import React from 'react'
import styled from 'styled-components'
import Text, { TextType } from '../Text'
import { useTranslation } from 'react-i18next'
import PinkPencil from '../../../assets/icons/pink_pencil.png'
interface Props {
  title: string
  showIcon?: boolean
}
const BuyHeader: React.FunctionComponent<Props> = ({ title, showIcon = true }: Props) => {
  const { t } = useTranslation()

  return (
    <Container>
      {showIcon && (
        <img src={PinkPencil} alt={t('basket')} className="image" />
      )}
      <Text type={TextType.TITLE} className="ui centered header main padding-5 App-header">
        {title}
      </Text>
    </Container>
  )
}

const Container = styled.div`
  .basket-img {
    margin-top: 2rem;
    height: 58px;
    width: 70px;
  }
  .App-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

export default BuyHeader
