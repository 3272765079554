import { createAsyncThunk } from '@reduxjs/toolkit'
import { AppService, RegisterRequest } from '../../models/services'
import { handleApiCallError } from 'store/helper'

export const registerRequestAsync = createAsyncThunk<void, RegisterRequest, { rejectValue: string }>(
  '/web/user/gift4you/register',
  async (body, { rejectWithValue }) => {
    const lang = localStorage.getItem('i18nextLng')
    try {
      return await AppService.register(body, lang)
    } catch (e) {
      return handleApiCallError(e, rejectWithValue)
    }
  }
)
